const palettes = require('@thehotelsnetwork/color-palettes');

const baseUrl = process.env.VUE_APP_API_URL;
const mercureUrl = process.env.VUE_APP_MERCURE_URL;
const nakamaUrl = process.env.VUE_APP_NAKAMA_URL;

const apiURL = {
  base: baseUrl,
  mercure: mercureUrl,
  nakama: nakamaUrl,
};

const i18nConfig = {
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
};

const languages = [
  { value: 'en', label: 'English' },
  { value: 'es', label: 'Español' },
  { value: 'de', label: 'Deutsch' },
  { value: 'fr', label: 'Français' },
  { value: 'it', label: 'Italiano' },
  { value: 'pt', label: 'Portuguese' },
];

const corporateWebLangs = ['en', 'es', 'de', 'fr', 'it', 'pt'];

const adminAccountTypes = ['account_owner', 'account_admin', 'admin'];

const defaultColors = {
  brand: palettes?.brand?.primary['500'] || '#0378CD',
  cluster: palettes?.charts?.amethyst['500'] || '#844FFF',
};

const authConfig = {
  domain: process.env.VUE_APP_AUTH_DOMAIN || 'thehotelsnetwork.eu.auth0.com',
  clientId: process.env.VUE_APP_AUTH_CLIENT_ID || 'n8DqKiVg2XtNOdysyNdVGGq91UhNNkzI',
};

const thnAppsUrl = {
  latte: process.env.VUE_APP_LATTE_URL,
  admin: process.env.VUE_APP_ADMIN_URL,
  benchdirect: process.env.VUE_APP_BDIRECT_URL,
  dashboards: process.env.VUE_APP_DASHBOARDS_URL,
  personalization: process.env.VUE_APP_PERSONALIZATION_URL,
  demand: process.env.VUE_APP_DEMAND_URL,
  kitt: process.env.VUE_APP_KITT_URL,
};

module.exports = {
  apiURL,
  languages,
  authConfig,
  i18nConfig,
  thnAppsUrl,
  defaultColors,
  adminAccountTypes,
  corporateWebLangs,
};
